

// 路由配置文件
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 2. 创建路由实例
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/miyupc/miyupc' // 直接重定向
          },
          // 咪语官网  2024.07.04新增
        {
            path: '/miyupc/miyupc',
            component: () => import('@/pages/miyupc/miyupc'),
        },
         // 浪啵啵官网  2025.01.03 新增
         {
          path: '/langBoBo/langBoBo',
          component: () => import('@/pages/langBoBo/langBoBo'),
      },
    ],
  });
  

  export default router;